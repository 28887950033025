import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import { downloadSingleTrackerApi } from 'apis/Auth';
import { apiErrorSnackBar } from 'utils/SnackBar';
import { useState } from 'react';
import { IconBrandApple, IconBrandUbuntu, IconBrandWindows, IconDownload } from '@tabler/icons';
import { useSelector } from 'react-redux';

const DownloadTracker = () => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const customization = useSelector((state) => state.customization);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggle = (e) => {
        setAnchorEl(e.currentTarget);
        setOpen((prevOpen) => !prevOpen);
    };

    const handleOSSelection = (type) => {
        downloadSingleTrackerApi({ type })
            .then((res) => {
                if (res.data && res.data.status === true) {
                    const a = document.createElement('a');
                    a.href = res.data.data;
                    a.download = type + 'Tracker';
                    a.click();
                } else {
                    apiErrorSnackBar(res);
                }
            })
            .catch((err) => {
                apiErrorSnackBar(err);
            });
    };

    return (
        <>
            <Avatar
                variant="rounded"
                aria-label="Tracker Download"
                sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    transition: 'all .2s ease-in-out',
                    background: theme.palette.secondary[200],
                    color: theme.palette.secondary[800],
                    '&[aria-controls="menu-list-grow"],&:hover': {
                        background: theme.palette.secondary.dark,
                        color: theme.palette.grey[50],
                        borderColor: theme.palette.secondary[800]
                    }
                }}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                color="inherit"
                onClick={handleToggle}
            >
                <IconDownload stroke={2} size="1.4rem" />
            </Avatar>
            <Popper
                placement="bottom-end"
                role={undefined}
                transition
                disablePortal
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 13]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    border={false}
                                    elevation={16}
                                    content={false}
                                    sx={{
                                        boxShadow: theme.shadows[16],
                                        ':hover': {
                                            boxShadow: theme.shadows[16]
                                        }
                                    }}
                                >
                                    <Box sx={{ p: 2 }}>
                                        <Stack>
                                            <Typography variant="h4">Download Trackers</Typography>
                                        </Stack>
                                        <Divider sx={{ mt: '15px' }} />
                                        <List
                                            component="nav"
                                            sx={{
                                                width: '100%',
                                                maxWidth: 350,
                                                minWidth: 150,
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: '10px',
                                                [theme.breakpoints.down('md')]: {
                                                    minWidth: '100%'
                                                },
                                                '& .MuiListItemButton-root': {
                                                    px: 1,
                                                    borderRadius: `${customization.borderRadius}px`
                                                }
                                            }}
                                        >
                                            <ListItemButton onClick={() => handleOSSelection('windows')}>
                                                <ListItemIcon>
                                                    <IconBrandWindows stroke={1.6} size={'1.7rem'} />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="h5">Windows</Typography>} />
                                            </ListItemButton>
                                            <ListItemButton onClick={() => handleOSSelection('mac')}>
                                                <ListItemIcon>
                                                    <IconBrandApple stroke={1.6} size={'1.7rem'} />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="h5">Mac</Typography>} />
                                            </ListItemButton>
                                            <ListItemButton onClick={() => handleOSSelection('linux')}>
                                                <ListItemIcon>
                                                    <IconBrandUbuntu stroke={1.6} size={'1.7rem'} />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="h5">Linux</Typography>} />
                                            </ListItemButton>
                                        </List>
                                    </Box>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

DownloadTracker.propTypes = {};

export default DownloadTracker;
