import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard routing
// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const Dashboard = Loadable(lazy(() => import('views/dashboard')));
const ProfilePage = Loadable(lazy(() => import('views/profile')));
const CalenderPage = Loadable(lazy(() => import('views/calender')));
const EmployeePage = Loadable(lazy(() => import('views/employee')));
const EmployeeUpdatePage = Loadable(lazy(() => import('views/employee/EmployeeUpdate')));

const LeavePage = Loadable(lazy(() => import('views/leaves/leave')));
const NewLeavePage = Loadable(lazy(() => import('views/leaves/newLeave')));
const LeaveTypePage = Loadable(lazy(() => import('views/leaves/leaveType')));
const LeaveBalancePage = Loadable(lazy(() => import('views/leaves/leaveBalance')));

const OrganizationPage = Loadable(lazy(() => import('views/organization/organization')));
const LocationPage = Loadable(lazy(() => import('views/organization/location')));
const DepartmentPage = Loadable(lazy(() => import('views/organization/department')));
const DesignationPage = Loadable(lazy(() => import('views/organization/designation')));
const AnnouncementPage = Loadable(lazy(() => import('views/organization/announcements')));

const AttendancePage = Loadable(lazy(() => import('views/timeSheet/attendance')));
const MonthlyAttendancePage = Loadable(lazy(() => import('views/timeSheet/monthlyAttendance')));
const TimeChangeRequestPage = Loadable(lazy(() => import('views/timeSheet/timeChangeRequest')));
const OfficeShiftPage = Loadable(lazy(() => import('views/timeSheet/officeShifts')));
const AddDaysOnPage = Loadable(lazy(() => import('views/timeSheet/addDaysOn')));
const PayrollPage = Loadable(lazy(() => import('views/payroll')));
const HolidaysPage = Loadable(lazy(() => import('views/timeSheet/holidays')));
const LoanPage = Loadable(lazy(() => import('views/loan')));
const TicketsPage = Loadable(lazy(() => import('views/tickets')));

const BurnDownReportPage = Loadable(lazy(() => import('views/reports/BurndownReport')));
const ProjectSummaryPage = Loadable(lazy(() => import('views/reports/ProjectSummary')));

const ClientsPage = Loadable(lazy(() => import('views/clients')));
const ProjectPage = Loadable(lazy(() => import('views/project/index')));
const TaskPage = Loadable(lazy(() => import('views/task/index')));

const PermissionPage = Loadable(lazy(() => import('views/rolePermission/permission')));
const RolePage = Loadable(lazy(() => import('views/rolePermission/role')));

const GeneralSettingsPage = Loadable(lazy(() => import('views/settings')));
const PayrollSettingsPage = Loadable(lazy(() => import('views/settings/PayrollSettings')));
// const SettingsPage = Loadable(lazy(() => import('views/settings')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        { id: 'dashboard', path: '/', element: <Dashboard /> },
        { id: 'profile', path: '/profile', element: <ProfilePage /> },
        {
            id: 'calender',
            path: '/calender',
            element: <CalenderPage />
        },
        {
            id: 'employees',
            path: 'employees',
            element: <EmployeePage />
        },
        {
            id: 'employees-create',
            path: 'employees/create',
            element: <CalenderPage />
        },
        {
            id: 'employees-edit',
            path: 'employees/:id/edit',
            element: <EmployeeUpdatePage />
        },
        {
            id: 'organizations',
            path: 'organizations',
            children: [
                {
                    id: 'organization',
                    path: 'organization',
                    element: <OrganizationPage />
                },
                {
                    id: 'location',
                    path: 'location',
                    element: <LocationPage />
                },
                {
                    id: 'department',
                    path: 'department',
                    element: <DepartmentPage />
                },
                {
                    id: 'designation',
                    path: 'designation',
                    element: <DesignationPage />
                }
            ]
        },
        {
            id: 'announcement',
            path: '/announcement',
            element: <AnnouncementPage />
        },
        // {
        //     id: 'leaves',
        //     path: '/leaves',
        //     element: <LeavesPage />
        // },
        {
            id: 'leaves',
            path: 'leaves',
            children: [
                {
                    id: 'leave',
                    path: 'leave',
                    element: <NewLeavePage />
                },
                {
                    id: 'leave-type',
                    path: 'leave-type',
                    element: <LeaveTypePage />
                },
                {
                    id: 'leave-balance',
                    path: 'leave-balance',
                    element: <LeaveBalancePage />
                }
            ]
        },
        {
            id: 'timesheet',
            path: 'timesheet',
            children: [
                {
                    id: 'attendance',
                    path: 'attendance',
                    element: <AttendancePage />
                },
                {
                    id: 'monthly-attendance',
                    path: 'monthly-attendance',
                    element: <MonthlyAttendancePage />
                },
                {
                    id: 'time-change-request',
                    path: 'time-change-request',
                    element: <TimeChangeRequestPage />
                },
                {
                    id: 'office-shift',
                    path: 'office-shift',
                    element: <OfficeShiftPage />
                },
                {
                    id: 'add-days-on',
                    path: 'add-days-on',
                    element: <AddDaysOnPage />
                }
            ]
        },
        {
            id: 'payroll',
            path: '/payroll',
            element: <PayrollPage />
        },
        {
            id: 'loan',
            path: '/loan',
            element: <LoanPage />
        },
        {
            id: 'holidays',
            path: '/holidays',
            element: <HolidaysPage />
        },
        {
            id: 'tickets',
            path: '/tickets',
            element: <TicketsPage />
        },
        {
            id: 'reports',
            path: 'reports',
            children: [
                {
                    id: 'burndown-report',
                    path: 'burndown-report',
                    element: <BurnDownReportPage />
                },
                {
                    id: 'project-summary',
                    path: 'project-summary',
                    element: <ProjectSummaryPage />
                }
            ]
        },
        {
            id: 'project-management',
            path: 'project-management',
            children: [
                {
                    id: 'clients',
                    path: 'clients',
                    element: <ClientsPage />
                },
                {
                    id: 'project',
                    path: 'project',
                    element: <ProjectPage />
                },
                {
                    id: 'tasks',
                    path: 'tasks',
                    element: <TaskPage />
                }
            ]
        },
        {
            id: 'role-permission',
            path: 'role-permission',
            children: [
                {
                    id: 'role',
                    path: 'role',
                    element: <RolePage />
                },
                {
                    id: 'permission',
                    path: 'permission',
                    element: <PermissionPage />
                }
            ]
        },
        {
            id: 'settings',
            path: '/settings',
            children: [
                {
                    id: 'general-settings',
                    path: 'general-settings',
                    element: <GeneralSettingsPage />
                },
                {
                    id: 'payroll-settings',
                    path: 'payroll-settings',
                    element: <PayrollSettingsPage />
                }
            ]
        }
    ]
};

export default MainRoutes;
