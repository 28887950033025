import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import { useSelector } from 'react-redux';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import useAuth from 'hooks/useAuth';

const Dashboard = Loadable(lazy(() => import('views/dashboard')));
const ProfilePage = Loadable(lazy(() => import('views/profile')));
const CalenderPage = Loadable(lazy(() => import('views/calender')));
const EmployeePage = Loadable(lazy(() => import('views/employee')));

const OrganizationPage = Loadable(lazy(() => import('views/organization/organization')));
const LocationPage = Loadable(lazy(() => import('views/organization/location')));
const DepartmentPage = Loadable(lazy(() => import('views/organization/department')));
const DesignationPage = Loadable(lazy(() => import('views/organization/designation')));
const AnnouncementPage = Loadable(lazy(() => import('views/organization/announcements')));

const LeavePage = Loadable(lazy(() => import('views/leaves/leave')));
const NewLeavePage = Loadable(lazy(() => import('views/leaves/newLeave')));
const LeaveTypePage = Loadable(lazy(() => import('views/leaves/leaveType')));
const LeaveBalancePage = Loadable(lazy(() => import('views/leaves/leaveBalance')));

const AttendancePage = Loadable(lazy(() => import('views/timeSheet/attendance')));
const MonthlyAttendancePage = Loadable(lazy(() => import('views/timeSheet/monthlyAttendance')));
const TimeChangeRequestPage = Loadable(lazy(() => import('views/timeSheet/timeChangeRequest')));
const OfficeShiftPage = Loadable(lazy(() => import('views/timeSheet/officeShifts')));
const AddDaysOnPage = Loadable(lazy(() => import('views/timeSheet/addDaysOn')));
const PayrollPage = Loadable(lazy(() => import('views/payroll')));
const HolidaysPage = Loadable(lazy(() => import('views/timeSheet/holidays')));
const LoanPage = Loadable(lazy(() => import('views/loan')));
const TicketsPage = Loadable(lazy(() => import('views/tickets')));

const BurnDownReportPage = Loadable(lazy(() => import('views/reports/BurndownReport')));
const ProjectSummaryPage = Loadable(lazy(() => import('views/reports/ProjectSummary')));

const ClientsPage = Loadable(lazy(() => import('views/clients')));
const ProjectPage = Loadable(lazy(() => import('views/project/index')));
const TaskPage = Loadable(lazy(() => import('views/task/index')));

const RolePage = Loadable(lazy(() => import('views/rolePermission/role')));
const PermissionPage = Loadable(lazy(() => import('views/rolePermission/permission')));

// const SettingsPage = Loadable(lazy(() => import('views/settings')));
const GeneralSettingsPage = Loadable(lazy(() => import('views/settings')));
const PayrollSettingsPage = Loadable(lazy(() => import('views/settings/PayrollSettings')));
// ==============================|| ROUTING RENDER ||============================== //

const routeElement = {
    dashboard: <Dashboard />,
    profile: <ProfilePage />,
    calender: <CalenderPage />,
    employees: <EmployeePage />,
    organization: <OrganizationPage />,
    location: <LocationPage />,
    department: <DepartmentPage />,
    designation: <DesignationPage />,
    announcement: <AnnouncementPage />,
    leave: <NewLeavePage />,
    leavetype: <LeaveTypePage />,
    leavebalance: <LeaveBalancePage />,
    attendance: <AttendancePage />,
    monthlyattendance: <MonthlyAttendancePage />,
    timechangerequest: <TimeChangeRequestPage />,
    officeshift: <OfficeShiftPage />,
    adddayson: <AddDaysOnPage />,
    payroll: <PayrollPage />,
    holidays: <HolidaysPage />,
    loan: <LoanPage />,
    tickets: <TicketsPage />,
    burndownreport: <BurnDownReportPage />,
    projectsummary: <ProjectSummaryPage />,
    clients: <ClientsPage />,
    project: <ProjectPage />,
    tasks: <TaskPage />,
    role: <RolePage />,
    permission: <PermissionPage />,
    generalsettings: <GeneralSettingsPage />,
    payrollsettings: <PayrollSettingsPage />
    // settings: <SettingsPage />
};

export default function ThemeRoutes() {
    const { access } = useAuth();
    const defaultPermissions = useSelector((state) => state.customization.defaultPermissions);
    const extraPermission = ['profile'];
    const userPermission = access && access.permissions ? [...access.permissions, ...extraPermission] : defaultPermissions;

    function checkPermission(object) {
        return object.filter((item) => {
            if (item.children) {
                if (checkPermission(item.children).length != 0) {
                    return (item.children = checkPermission(item.children));
                }
            } else {
                return userPermission.includes(item.id);
            }
        });
    }

    const objMenuRoutes = JSON.parse(JSON.stringify(MainRoutes));

    function addElement(object) {
        return object.map((item) => {
            if (item.element) {
                item.element = routeElement[item.id.replace(/[-]/g, '')];
            }
            item.children && addElement(item.children);
        });
    }

    let routeByPermission = checkPermission(objMenuRoutes.children);
    addElement(objMenuRoutes.children);

    const NewMainRoutes = {
        path: MainRoutes.path,
        element: MainRoutes.element,
        children: routeByPermission
    };

    return useRoutes([NewMainRoutes, AuthenticationRoutes]);
    // return useRoutes([MainRoutes, AuthenticationRoutes]);
}
