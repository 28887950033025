// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import useAuth from 'hooks/useAuth';
import { useSelector } from 'react-redux';
import {
    Business,
    CalendarMonthOutlined,
    ContentPasteSearchOutlined,
    Campaign,
    Groups2Outlined,
    Hail,
    Kitesurfing,
    ManageHistoryOutlined,
    MilitaryTechOutlined,
    Speed,
    SettingsOutlined,
    Style,
    PaymentsOutlined,
    SummarizeOutlined
} from '@mui/icons-material';

const menuIcons = {
    dashboard: Speed,
    calender: CalendarMonthOutlined,
    employees: Groups2Outlined,
    organizations: Business,
    announcement: Campaign,
    leaves: Hail,
    payroll: PaymentsOutlined,
    loan: PaymentsOutlined,
    holidays: Kitesurfing,
    tickets: Style,
    timesheet: ManageHistoryOutlined,
    reports: SummarizeOutlined,
    projectmanagement: ContentPasteSearchOutlined,
    rolepermission: MilitaryTechOutlined,
    settings: SettingsOutlined
};

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { access } = useAuth();
    const defaultPermissions = useSelector((state) => state.customization.defaultPermissions);
    const userPermission = access && access.permissions ? access.permissions : defaultPermissions;

    // Check Permissions
    function checkPermission(object) {
        return object.filter((item) => {
            if (item.children) {
                if (checkPermission(item.children).length != 0) {
                    return (item.children = checkPermission(item.children));
                }
            } else {
                return userPermission.includes(item.id);
            }
        });
    }

    const objMenuItems = JSON.parse(JSON.stringify(menuItem.items));

    function addIcon(object) {
        return object.map((item) => {
            if (item.isIcon) {
                item.icon = menuIcons[item.id.replace('-', '')];
            } else {
                item.children && addIcon(item.children);
            }
        });
    }

    let menuByPermission = objMenuItems.filter((b) => {
        return checkPermission(b.children);
    });
    menuByPermission.filter((b) => addIcon(b.children));

    let newArray = [];
    objMenuItems.map((item) => {
        newArray.push({
            id: item.id,
            type: item.type,
            children: checkPermission(item.children)
        });
    });

    const navItems = newArray.map((item) => {
        // const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
