// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// action - account reducer
export const LOGIN = '@account/LOGIN';
export const LOGOUT = '@account/LOGOUT';

export const SET_MIMIC = '@mimic/LOGIN';
export const REMOVE_MIMIC = '@mimic/LOGOUT';

export const SNACKBAR_OPEN = '@snackbar/SNACKBAR_OPEN';
export const TASK_SELECTION = '@task/SELECTION';

// action - Loader
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
