import { REMOVE_MIMIC, SET_MIMIC } from './actions';

export const initialState = {
    isMimic: false,
    mimic: {}
};
const mimicReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MIMIC: {
            const { isMimic, mimic } = action.payload;
            return {
                ...state,
                isMimic,
                mimic
            };
        }
        case REMOVE_MIMIC: {
            return {
                isMimic: false,
                mimic: {}
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default mimicReducer;
