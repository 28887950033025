// action - state management
import config from 'config';
import * as actionTypes from './actions';

export const initialState = {
    isOpen: ['dashboard'], // for active default menu
    defaultId: 'dashboard',
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    opened: true,
    defaultPermissions: [
        'dashboard',
        'profile',
        'calender',
        'employees',
        'organization',
        'location',
        'department',
        'designation',
        'announcement',
        'leave',
        'new-leave',
        'leave-type',
        'leave-balance',
        'attendance',
        'monthly-attendance',
        'time-change-request',
        'office-shift',
        'payroll',
        'loan',
        'holidays',
        'tickets',
        'burndown-report',
        'project-summary',
        'clients',
        'project',
        'tasks',
        'role',
        'permission',
        'ticket',
        'general-settings',
        'payroll-settings'
        // 'settings'
    ]
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
    let id;
    switch (action.type) {
        case actionTypes.MENU_OPEN:
            id = action.id;
            return {
                ...state,
                isOpen: [id]
            };
        case actionTypes.SET_MENU:
            return {
                ...state,
                opened: action.opened
            };
        case actionTypes.SET_FONT_FAMILY:
            return {
                ...state,
                fontFamily: action.fontFamily
            };
        case actionTypes.SET_BORDER_RADIUS:
            return {
                ...state,
                borderRadius: action.borderRadius
            };
        default:
            return state;
    }
};

export default customizationReducer;
